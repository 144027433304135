// Entry point for the build script in your package.json
// import "@hotwired/turbo-rails"
import "./controllers"

import "trix"
import "@rails/actiontext"

import Vue from 'vue/dist/vue.esm.js'

import axios from './src/common/api.js'
Vue.prototype.$axios = axios;

// import ahDocumentShow from './components/documents/show'
// import ahDocumentFiles from './components/documents/files'
// import ahDocumentSwitch from './components/documents/switch'
import './src/file/direct_uploads.js'
import ElementUI from 'element-ui';
import lang from 'element-ui/lib/locale/lang/ru-RU.js';
import locale from 'element-ui/lib/locale';
locale.use(lang);
Vue.use(ElementUI);
import Chartkick from 'vue-chartkick'
Chartkick.configure({language: "ru"})
Vue.use(Chartkick)

document.addEventListener('DOMContentLoaded', () => {
    new Vue({
        components: {
            // 'ah-document-show': ahDocumentShow,
            // 'ah-document-files': ahDocumentFiles,
            // 'ah-document-paid-switch': ahDocumentSwitch
        },

        mounted() {
            // require("trix")
            // require("@rails/actiontext")
        },

        computed: {
            chart() {
                return { ohlcv: this.$props.data }
            },

            title() {
                return 'test'
            },

            colors() {
                return this.$props.night ? {} : {
                    colorBack: '#fff',
                    colorGrid: '#eee',
                    colorText: '#333',
                    colorTitle: '#333'
                }
            },
        },

        data: {
            showDetail: false
        }
    }).$mount('#app')
})
